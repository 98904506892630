"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.STATUS_MAPPER = exports.MESSAGE_MAPPER = void 0;
exports.MESSAGE_MAPPER = {
    sent: 'Pending acceptance and payment addition.',
    'accepted-added-normal': 'Payment method added successfully. First payment being processed.',
    'accepted-valid-normal': 'Payment method added and auto-charge working correctly.',
    'accepted-failed-normal': 'Payment attempt failed. Add new payment option.',
    'accepted-added-migrated': 'Agreement migrated successfully. First payment being processed.',
    'accepted-valid-migrated': 'Agreement migrated and auto-charge working correctly.',
    'accepted-failed-migrated': 'Payment attempt failed. Add new payment option.',
};
exports.STATUS_MAPPER = {
    sent: 'pending',
    'accepted-added-normal': 'success',
    'accepted-valid-normal': 'success',
    'accepted-failed-normal': 'error',
    'accepted-added-migrated': 'success',
    'accepted-valid-migrated': 'success',
    'accepted-failed-migrated': 'error',
};
